.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  border-radius: 3px;
  transition: 0.3s;
  outline: none;
}

.DayPicker-Caption {
  color: rgba(0, 0, 0, 0.7);
}

.Selectable .DayPicker-Day {
  border-radius: 0;
  padding: 10px;
  outline: none;
}

.DayPicker-Day--today {
  font-weight: 700;
  color: #4A90E2;
}

.Selectable .DayPicker-Day--start,
.Selectable .DayPicker-Day--end {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 3px;
  font-weight: 700;
  z-index: 1;
}
