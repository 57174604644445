@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@media print {
  .header-left-side-info_semi_bold {
    font-weight: 400;
  }

  .header-left-side-address,
  .header-right-side {
    font-weight: 500;
  }

  .header-left-side-info_bold {
    font-weight: 700;
  }

  .header-left-side-info_bold,
  .header-left-side-info_semi_bold {
    padding: 10px 0;
  }
  @page {
    size: A4 landscape;
  }
  .pdf-main {
    font-family: 'Roboto', sans-serif;
    padding: 0 50px;
    width: 297mm; /* A4 landscape width */
    height: 210mm;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 100px 0 50px;
    height: 300px;
  }

  .header-right-side {
    width: 30%;
  }

  .header-right-side > div {
    margin: 15px 0;
  }

  .header-left-side {
    width: 70%;
  }

  .header-left-side-address,
  .header-left-side-info {
    padding: 10px 0;
  }

  .roomservice-logo img {
    max-width: 150px;
    max-height: 100px;
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    flex-wrap: nowrap;
    font-size: 13px;
  }

  .border-bottom {
    border-bottom: 1px solid #a8a8a8;
  }

  .table-head {
    color: rgb(170, 170, 170);
    border-bottom: 3px solid black;
  }
  
  .table-body {
    border-bottom: 3px solid black;
  }

  .table-footer {
    border-bottom: 1.5px solid black;
  }

  .footer-info {
    margin-top: 2px;
    border-top: 1.5px solid black;
    font-weight: 500;
    text-align: center;
    font-size: 13px;
    padding: 15px;
  }

  .cell-0 {
    width: 100px;
    display: flex;
    box-sizing: border-box;
  }
  .cell-1,
  .cell-2,
  .cell-3 {
    width: 170px;
  }

  .cell-4,
  .cell-5 {
    width: 125px;
  }

  .cell-6 {
    width: 100px;
  }

  .cell-0 {
    text-align: left;
  }
  .cell-6 {
    text-align: right;
  }

  .cell-1,
  .cell-2,
  .cell-3,
  .cell-4,
  .cell-5 {
    text-align: center;
  }

  .cell-0,
  .cell-1,
  .cell-2,
  .cell-3,
  .cell-4,
  .cell-5,
  .cell-6 {
    overflow-wrap: break-word;
  }

  .cell-0,
  .cell-1,
  .cell-2,
  .cell-3,
  .cell-4,
  .cell-5,
  .cell-6,
  .cell-7,
  .cell-8 {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }
}