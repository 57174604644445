@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@media print {
  .header-left-side-info_semi_bold {
    font-weight: 400;
  }

  .header-left-side-address,
  .header-right-side {
    font-weight: 500;
  }

  .header-left-side-info_bold {
    font-weight: 700;
  }

  .header-left-side-info_bold,
  .header-left-side-info_semi_bold {
    padding: 10px 0;
  }

  .pdf-main {
    font-family: 'Roboto', sans-serif;
    /* equivalent to A4 landscape orientation size in pixels + 100px*/
    width: 942px;
    height: 100v;
    padding: 0 100px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 100px 0 50px;
    height: 300px;
  }

  .header-right-side {
    width: 30%;
  }

  .header-right-side > div {
    margin: 15px 0;
  }

  .header-left-side {
    width: 70%;
  }

  .header-left-side-address,
  .header-left-side-info {
    padding: 50px 0;
  }

  .roomservice-logo img {
    max-width: 150px;
    max-height: 100px;
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .border-bottom {
    border-bottom: 1px solid #a8a8a8;
  }

  .table-head {
    color: rgb(170, 170, 170);
    border-bottom: 3px solid black;
  }
  
  .table-body {
    border-bottom: 3px solid black;
  }

  .table-footer {
    border-bottom: 1.5px solid black;
  }

  .footer-info {
    margin-top: 2px;
    border-top: 1.5px solid black;
    font-weight: 500;
    text-align: center;
    font-size: 13px;
    padding: 15px;
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }

  .footer-cell {
    font-weight: 300;
    margin: auto 0;
    overflow-wrap: break-word;
    text-align: center;
  }

  .footer-cell-0 {
    width: 270px;
    text-align: left;
  }

  .footer-cell-1 {
    width: 0px;
  }

  .footer-cell-2,
  .footer-cell-3 {
    width: 170px;
  }

  .footer-cell-4,
  .footer-cell-5 {
    width: 125px;
  }

  .footer-cell-6 {
    width: 100px;
    text-align: right;
  }

  .footer-totals-cell {
    font-weight: 500;
  }

  .footer-vat-cell {
    font-weight: 400;
  }

  .footer-vat-row {
    border-bottom: 2px solid black;
  }

  .footer-all-totals-row {
    padding-top: 50px;
  }

  .cell-0 {
    width: 100px;
  }
  .cell-1,
  .cell-2,
  .cell-3 {
    width: 170px;
  }

  .cell-4,
  .cell-5 {
    width: 125px;
  }

  .cell-6 {
    width: 100px;
  }

  .cell-0 {
    text-align: left;
  }
  .cell-6 {
    text-align: right;
  }

  .cell-1,
  .cell-2,
  .cell-3,
  .cell-4,
  .cell-5 {
    text-align: center;
  }

  .cell-0,
  .cell-1,
  .cell-2,
  .cell-3,
  .cell-4,
  .cell-5,
  .cell-6,
  .cell-7 {
    margin: auto 0;
    overflow-wrap: break-word;
  }
}